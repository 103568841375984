.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: radial-gradient(rgb(255, 0, 191) 0%, rgb(128, 0, 0) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.checkBox{
  text-align: left;
  padding: 100px;
  flex: content;
}
.checkBox input,label{
  margin-bottom: 50px;
}
.checkBox input{
  zoom: 150%;
}
.checkBox label{
  padding-right: 100px;
}
.checkBox button{
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 200%;
  color: white;
  top:70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;
  width: 500px;
  height: 100px;
}
.container:hover .button{
  color:transparent;
}

.container:hover #heart{
  visibility: visible;
}
.container{
  position: absolute;
  transform: translate(-50%, -50%);
  top:70%;
  left: 50%;
  width: 500px;
  height: 100px;
}

#heart{
  visibility: hidden;
  position: absolute;
  top:73%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 200px;
}

.button:hover#heart{
  visibility: hidden;
}
